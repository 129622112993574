
  import { Component, Prop } from 'vue-property-decorator'
  import Cell from './cell'
  import { LinkedCellOptions } from '@/components/dataTables/cell/index'

@Component
  export default class ActionCell extends Cell {
  @Prop({ type: Boolean, default: true }) displaySpanName!: boolean
  declare options: LinkedCellOptions

  get action () {
    return this.options?.action
  }

  get contact () {
    const { item } = this

    if (!item) return undefined
    const { contact } = this

    return contact
  }

  get icon () {
    const { action, item } = this

    return action?.icon || item?.icon
  }

  get iconColor () {
    const { options } = this

    return options?.action?.iconColor || 'white'
  }

  get color () {
    const { action, item } = this

    return action?.color || item?.color
  }

  get tooltip () {
    const { options, item } = this

    return options?.tooltip || item?.tooltip || item?.name || item?.contact?.name
  }

  trigger (value) {
    const { action, item, options, process, system } = this

    const trigger = action?.trigger || item?.trigger

    if (trigger) {
      if (options?.action?.messaging) {
        return trigger(value, process?.config?.messaging, system)
      } else {
        return trigger(value)
      }
    }
  }

  get position () {
    const { item } = this
    if (!item) return 'top'
    const { position = 'top' } = item
    return position
  }

  get top () {
    const { position } = this

    return position === 'top'
  }

  get left () {
    const { position } = this

    return position === 'left'
  }

  get disabledButton () {
    const { options, item, disabled: disabledBtn } = this

    if (disabledBtn || !item) return true

    if (typeof item === 'string' && item.length) return false
    if (typeof item === 'number' && !isNaN(item)) return false
    if (item && 'disabled' in item) {
      const { disabled } = item

      return disabled
    }
    if (!options) {
      return false
    }

    const { options: { disabled, disabledMissingProperty, disabledActionMissingProperty }, process } = this

    if (disabledActionMissingProperty && options?.action?.messaging && process?.config?.messaging) {
      const path = process?.config?.messaging[disabledActionMissingProperty.key].phone

      return this.isMissingPathProperty(path, item)
    }

    return disabled || this.isMissingProperty(disabledMissingProperty, item)
  }

  get displayName () {
    const { action, displaySpanName } = this
    return action && !action.only && displaySpanName
  }

  isMissingProperty (props, item) {
    if (!props?.length || !item) return false
    return props.some(prop => !item[prop])
  }
  }
