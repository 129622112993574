
  import { Component } from 'vue-property-decorator'
  import Cell from './cell'
  import { LinkedCellOptions } from '.'
  import { getObjectValueByPath } from '@/utils/vuetify/helpers'
  import ActionCell from '@/components/dataTables/cell/ActionCell.vue'

@Component({
  components: { ActionCell },
})
  export default class LinkedCell extends Cell {
  declare options: LinkedCellOptions

  get action () {
    return this.options?.action
  }

  get canTriggerAction () {
    const { item } = this

    return Boolean(item)
  }

  get value () {
    const { item } = this
    return getObjectValueByPath(item, this.options?.path || 'name')
  }

  get tooltip () {
    return this.value || this.options?.tooltip || this.itemValue?.tooltip
  }

  get itemValue () {
    const { item } = this
    return item
  }

  triggerOptions (item) {
    const { options } = this
    if (!options?.trigger) return

    return options.trigger(item)
  }

  get isPpu () {
    const { value } = this
    if (!value) return false

    const chain = value.split(' ')
    const pattern = /^([^AEIOUaeiou0-9]{4}[0-9]{2})/
    const match = chain[chain.length - 1].match(pattern)

    return !!match
  }

  get splitChain () {
    const { value } = this

    const chain = value.split(' ')
    const pattern = /^([^AEIOUaeiou0-9]{4}[0-9]{2})/
    const match = chain[chain.length - 1].match(pattern)

    if (match) {
      const matchedPart = match[0]
      const unmatchedPart = value.replace(matchedPart, '').trim()
      return { matchedPart, unmatchedPart }
    } else {
      return { matchedPart: '', unmatchedPart: value }
    }
  }

  get disabledButton () {
    const { options: { disabledActionMissingProperty }, process, options, item } = this

    if (disabledActionMissingProperty && options?.action?.messaging && process?.config?.messaging) {
      const path = process?.config?.messaging[disabledActionMissingProperty?.key]?.phone
      return this.isMissingPathProperty(path, item)
    }

    return false
  }
  }
